
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuardServiceService } from './services/auth-guard-service.service';
import { AuthGuardGuard } from './services/auth-guard.guard';
import { AutologinGuard } from './services/autologin.guard';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule),
    canActivate: [AuthGuardServiceService]

  },
  {
    path: '',
    redirectTo: 'initial-slider',
    pathMatch: 'full'
  },
  {
    path: 'otp-generate',
    loadChildren: () => import('./auth/otp-generate/otp-generate.module').then( m => m.OtpGeneratePageModule),
    canActivate: [AutologinGuard]  //2
  },
  {
    path: 'register',
    loadChildren: () => import('./auth/register/register.module').then( m => m.RegisterPageModule),
    canActivate: [AutologinGuard]
  },
  {
    path: 'otp-verify/:mobile',
    loadChildren: () => import('./auth/otp-verify/otp-verify.module').then( m => m.OtpVerifyPageModule),
    canActivate: [AutologinGuard] //3
  },
  {
    path: 'report-view',
    loadChildren: () => import('./home/views/report-view/report-view.module').then( m => m.ReportViewPageModule)
  },
  {
    path: 'invite-share',
    loadChildren: () => import('./promote-app/invite-share/invite-share.module').then( m => m.InviteSharePageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./auth/login/login.module').then( m => m.LoginPageModule),
    canActivate: [AutologinGuard] //4

  },
  {
    path: 'google',
    loadChildren: () => import('./auth/google/google.module').then( m => m.GooglePageModule)
  },
  {
    path: 'register-success',
    loadChildren: () => import('./auth/register-success/register-success.module').then( m => m.RegisterSuccessPageModule),
    canActivate: [AutologinGuard]
  },
  {
    path: 'initial-slider',
    loadChildren: () => import('./auth/initial-slider/initial-slider.module').then( m => m.InitialSliderPageModule),
    canActivate: [AutologinGuard]  //1
  },
  {
    path: 'forget-password',
    loadChildren: () => import('./auth/forget-password/forget-password.module').then( m => m.ForgetPasswordPageModule),
    canActivate: [AutologinGuard]
  },
  {
    path: 'terms-conditions',
    loadChildren: () => import('./auth/terms-conditions/terms-conditions.module').then( m => m.TermsConditionsPageModule)
  },
  {
    path: 'change-password',
    loadChildren: () => import('./change-password/change-password.module').then( m => m.ChangePasswordPageModule)
  },
  {
    path: 'health-tips',
    loadChildren: () => import('./health-tips/health-tips.module').then( m => m.HealthTipsPageModule)
  },
  {
    path: 'home/blog-view/:id',
    loadChildren: () => import('./home/blog-view/blog-view.module').then( m => m.BlogViewPageModule)
  },
  {
    path: 'edit-profile',
    loadChildren: () => import('./home/edit-profile/edit-profile.module').then( m => m.EditProfilePageModule)

  },
  {
    path: 'add-healthtips',
    loadChildren: () => import('./add-healthtips/add-healthtips.module').then( m => m.AddHealthtipsPageModule)
  },
  {
    path: 'support',
    loadChildren: () => import('./home/support/support.module').then( m => m.SupportPageModule)
  },
  {
    path: 'faq',
    loadChildren: () => import('./home/faq/faq.module').then( m => m.FaqPageModule)
  },
  {
    path: 'home/blog-view/:id',
    loadChildren: () => import('./home/blog-view/blog-view.module').then( m => m.BlogViewPageModule)
  },
  {
    path: 'home/blog-add',
    loadChildren: () => import('./home/blog-add/blog-add.module').then( m => m.BlogAddPageModule)
  },
  {
    path: 'health-tips-vew/:id',
    loadChildren: () => import('./health-tips-vew/health-tips-vew.module').then( m => m.HealthTipsVewPageModule)
  },
  {
    path: 'patient-chat/:id',
    loadChildren: () => import('./patient-chat/patient-chat.module').then( m => m.PatientChatPageModule)
  },
  {
    path: 'addprescription',
    loadChildren: () => import('./addprescription/addprescription.module').then( m => m.AddprescriptionPageModule)
  },
  {
    path: 'viewprescription',
    loadChildren: () => import('./viewprescription/viewprescription.module').then( m => m.ViewprescriptionPageModule)
  },
  {
    path: 'editprescription',
    loadChildren: () => import('./editprescription/editprescription.module').then( m => m.EditprescriptionPageModule)
  },
  {
    path: 'updatedoctorslot',
    loadChildren: () => import('./updatedoctorslot/updatedoctorslot.module').then( m => m.UpdatedoctorslotPageModule)
  },
  {
    path: 'appoint-details/:id',
    loadChildren: () => import('./home/views/appoint-details/appoint-details.module').then( m => m.AppointDetailsPageModule)
  },
  {
    path: 'addnotes',
    loadChildren: () => import('./addnotes/addnotes.module').then( m => m.AddnotesPageModule)
  },
  {
    path: 'doctorreviews',
    loadChildren: () => import('./doctorreviews/doctorreviews.module').then( m => m.DoctorreviewsPageModule)
  },
  {
    path: 'slotavailable',
    loadChildren: () => import('./slotavailable/slotavailable.module').then( m => m.SlotavailablePageModule)
  },
  {
    path: 'doctoraccounthistory',
    loadChildren: () => import('./doctoraccounthistory/doctoraccounthistory.module').then( m => m.DoctoraccounthistoryPageModule)
  },
  {
    path: 'terms',
    loadChildren: () => import('./terms/terms.module').then( m => m.TermsPageModule)
  },
  {
    path: 'viewreports',
    loadChildren: () => import('./home/views/report-view/report-view.module').then( m => m.ReportViewPageModule)
  },
  {
    path: 'helpsupport',
    loadChildren: () => import('./helpsupport/helpsupport.module').then( m => m.HelpsupportPageModule)
  },






];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
