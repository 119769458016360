import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AuthenticationServiceService } from './services/authentication-service.service';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { GraphQLModule } from './graphql.module';
import { HttpClientModule } from '@angular/common/http';
import { AuthGuardServiceService } from './services/auth-guard-service.service';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { NativeGeocoder } from '@ionic-native/native-geocoder/ngx';

import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IonicSelectableModule } from 'ionic-selectable';
import { LocationAccuracy } from '@awesome-cordova-plugins/location-accuracy/ngx';
import { Network } from '@awesome-cordova-plugins/network/ngx';
import 'hammerjs';
import { OneSignal } from '@ionic-native/onesignal/ngx';
import { SmsRetriever } from '@ionic-native/sms-retriever/ngx';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';




@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [BrowserModule,ReactiveFormsModule,IonicSelectableModule, IonicModule.forRoot(), AppRoutingModule,FormsModule, GraphQLModule, HttpClientModule,NgxMaterialTimepickerModule,BrowserAnimationsModule,],
  providers: [
    Network,
    AuthenticationServiceService,
    SmsRetriever,
    Geolocation,
    NativeGeocoder,
    OneSignal,
    LocationAccuracy,
    AndroidPermissions,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },

  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
