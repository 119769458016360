import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { Apollo,gql } from 'apollo-angular';
import { tap, delay } from 'rxjs/operators';
import { HttpClient,HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(public toastController: ToastController,
    private apollo:Apollo,
    private _httpClient: HttpClient) {  }

  async presentToast(msg,color,position) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 3000,
      color:color,
      position:position,
    });
    toast.present();
  }

  get_doctor_id()
  {

    return this.apollo
    .watchQuery({
    query: gql`
      {
          doctor_registration {
            id
            first_name
            register_number
            number_serial
          }
      }
    `,
    })
    .valueChanges.pipe(
    tap((data) => {
        data;
    })
    );

  }

    unread_get_notify(id)
    {


      const un_read = gql`
  subscription notification {
    tele_notification(where: {doctor_id: {_eq: ${id}}, read_status: {_in: "un_read"}}) {
      name
      id
      descrption
      appointment_id
      patients_id
      create_date
    }
  }`;
  return this.apollo.subscribe({
      query: un_read,
      fetchPolicy:'network-only'
    }).pipe(
      tap((data) => {
          data;
      })
      );

    }


    update_notification_status(id){
      const update_notify = gql`
      mutation MyMutation {
        update_tele_notification(where: {id: {_eq: ${id}}}, _set: {read_status: "read"}) {
          returning {
            id
            read_status
          }
        }
      }`;

      return this.apollo.mutate({
        mutation: update_notify,

      }).pipe(
        tap((data) => {
          data;
        })
      );
    }
    get_notification(id,page,limit){
      const support_chat = gql`
      subscription notification {
        tele_notification(where: {doctor_id: {_eq: ${id} } }, order_by: {id: desc},limit:${limit},offset:${page}) {
          descrption
          name
          id
          create_date
          appointment_id
          patients_id
          read_status
          status_id
        }
      }`;
      return this.apollo.subscribe({
          query: support_chat
        }).pipe(
          tap((data) => {
              data;
          })
          );
    }
    read_notification(id){
      const notification = gql`
      mutation MyMutation($id: Int!) {
        update_tele_notification(where: {id: {_eq: $id}}, _set: {read_status: "read"}) {
          returning {
            id
          }
        }
      }`;
      return this.apollo.mutate({
          mutation: notification,
          variables: id={id: id}
        }).pipe(
          tap((data) => {
              data;
          })
          );
        }

}
