import { Injectable } from '@angular/core';
import{ BehaviorSubject } from 'rxjs';
import{ Router } from '@angular/router';
import{ ToastController, Platform, AlertController } from '@ionic/angular';
import { Preferences } from '@capacitor/preferences';
import { gql, Apollo } from 'apollo-angular';
import { tap } from 'rxjs/operators';

declare var cookieMaster;

@Injectable({
  providedIn: 'root'
})


export class AuthenticationServiceService {

  authState = new BehaviorSubject(false);

  Authenticated: BehaviorSubject<boolean> = new BehaviorSubject(null);
  token: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  userdata: BehaviorSubject<any> = new BehaviorSubject<any>(null);


  constructor(
    private router: Router,
    private apollo:Apollo,
    private platform: Platform,
    public toastController: ToastController
  ) {
    this.gettoken();

    this.platform.ready().then(() => {
      this.ifLoggedIn();
      });
  }

  async gettoken(){
    //  return !!localStorage.getItem("token");
 const token = localStorage.getItem("accessToken");
    //  const user = localStorage.getItem("user_data");

    //const token = await Storage.get({ key: 'accessToken' });
    const user = await Preferences.get({ key: 'user_data' });
    if (token) {
      console.log('set token: ', token);
      this.token.next(token);
      this.userdata.next(JSON.parse(user.value));
      this.Authenticated.next(true);
    } else {
      this.token.next(null);
      this.Authenticated.next(false);
      this.userdata.next(null);
    }

    // token.then(response => {
    //   if (response.value) {
    //     this.token.next(response.value);
    //     this.authState.next(true);
    //     this.Authenticated.next(true);
    //     this.userdata.next(response.value);
    //   }
    //   else {
    //     this.token.next(null);
    //     this.Authenticated.next(false);
    //      this.userdata.next(null);
    //   }
    // }
    // )
    // return this.token.value;
  }
//       const user =  Storage.get({ key: 'user_data' });

//      console.log('gettoken function authservice', token);
//      console.log('user details auth servicer', user);

//     if(token){
//       this.token.next(token);
//        this.userdata.next(JSON.parse(user));
//       this.Authenticated.next(true);

//     }  else {
//       this.token.next(null);
//       this.Authenticated.next(false);
//        this.userdata.next(null);
//     }


// }

  ifLoggedIn() {
    const userdata =  Preferences.get({ key: 'user_data' });
    userdata.then(response => {
      // console.log(response);
      if (response.value) {
        this.authState.next(true);
      }
    })
  }

  ifLoggedIn_login() {
    const userdata =  Preferences.get({ key: 'user_data' });
    userdata.then(response => {
      // console.log(response);
      if (response.value) {
        this.authState.next(true);
        // this.router.navigate(['home/home-list']);
      }
    })
  }


  ifLoggedIn_register() {
    const userdata =  Preferences.get({ key: 'user_data' });
    userdata.then(response => {
       if (response.value) {
        this.authState.next(true);
        // this.router.navigate(['home/home-list']);
      }
    })
  }

  ifLoggedIn_otp() {
    const userdata =  Preferences.get({ key: 'user_data' });
    userdata.then(response => {
       if (response.value) {
        this.authState.next(true);
        this.router.navigate(['home/home-list']);
      }
    })
  }


  logout() {
    this.authState.next(null);
    this.authState.next(false);
    localStorage.removeItem('accessToken');
    localStorage.removeItem('onesignalid');

    const userdata =  Preferences.remove({ key: 'user_data' });
    this.router.navigate(['/login'],{skipLocationChange: true});
  }


  isAuthenticated() {
    const userdata =  Preferences.get({ key: 'user_data' });
    userdata.then(response => {
      if (response.value) {
        this.authState.next(true);
        return true;
        // console.log(this.authState.value);
      }
    }
    )
    return this.authState.value;
  }

  async store_data(token,datas)
  {
      localStorage.setItem("accessToken", token);
      this.getUsers(token)
        .subscribe((result: any) => {
          Preferences.set({
            key: 'user_data',
            value: JSON.stringify(result.data.doctor_registration[0]),
          });

          this.ifLoggedIn();
          this.router.navigate(['home/home-list']);
        });
  }


  getUsers(token)
  {

    // console.log(token);
    // localStorage.setItem("accessToken", token);
    return this.apollo
      .watchQuery({
        query: gql`
          {
            doctor_registration {
              email
              first_name
              gender
              id
              last_name
              mobile
              address_line
              addressline1
              city
              country
              languages
              state
              register_number
            }
          }
        `,
      })
      .valueChanges.pipe(
        tap((data) => {
            data;
        })
      );
  }


  sendotp(mobile,sms_code){
    console.log(mobile);
      const slot_booking = gql`
      mutation MyMutation {
        sendOtp(phone_number: "${mobile.phoneNumber}",sms_code:"${sms_code}") {
          result {
            status
            message
          }
        }
      }`;

    return this.apollo.mutate({
      mutation: slot_booking,

    }).pipe(
      tap((data) => {
        data;
      })
    );
  }

  delete_onesignalkey(onesignalid,platform_type,res_users){
    const delete_onesignal = gql`
      mutation MyMutation {
        del_onesignals(key: "${onesignalid}", user_id: ${res_users}) {
          result {
            message
          }
        }
    }`;

    return this.apollo.mutate({
      mutation: delete_onesignal,
    }).pipe(
      tap((data) => {
        data;
      })
    );
  }


  otplogin(otpdata){

    const slot_booking = gql`
    mutation MyMutation {
      Otplogin(otp: "${otpdata.otp}", phone_number: "${otpdata.phone_number}") {
        result {
          accessToken
          message
          status
          type_users
          status_message
          data {
            email
            name
            phone_number
          }
        }
      }
    }`;

    return this.apollo.mutate({
      mutation: slot_booking,

    }).pipe(
      tap((data) => {
        data;
      })
    );

  }


  onesignal_id(id,platform_type){
    const slot_booking = gql`
    mutation MyMutation {
      insert_one_signal_master(objects: {key: "${id}",signal_type:${platform_type}}) {
        returning {
          id
          key
        }
      }
    }`;

    return this.apollo.mutate({
      mutation: slot_booking,

    }).pipe(
      tap((data) => {
        data;
      })
    );
  }


}







