import { Injectable } from '@angular/core';
import { Apollo,gql,QueryRef } from 'apollo-angular';
import { tap, delay } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';
import { Subscription } from 'rxjs';
import{ BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class CommonServiceService {

  private querySubscription: Subscription;
  profileimage: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  editSlot: BehaviorSubject<any> = new BehaviorSubject<any>(null);


  postsQuery: QueryRef<any>;
  doctorfiles: QueryRef<any>;

  constructor(private apollo:Apollo) { }


get_language_list()
{

  return this.apollo
  .watchQuery({
    query: gql`
      {
        res_lang(order_by: {name: asc}, where: {active: {_eq: true}}) {
          id,
          name
        }
      }
    `,
  })
  .valueChanges.pipe(
    tap((data) => {
        data;
    })
  );

}
getUserdata(token:string){
  console.log("fgd")
  this.postsQuery = this.apollo
    .watchQuery({
      query: gql`{
        doctor_registration {
          id
          first_name
          last_name
          medical_speacialization {
            id
            name
          }
          doctor_registration_medical_qualification_rels {
            medical_qualification_id
            doctor_registration_id
            medical_qualification {
              name
            }
          }
          doctor_registration_res_lang_rels {
            res_lang_id
            doctor_registration_id
            res_lang {
              name
            }
          }
          res_country_state {
            name
          }
          res_city {
            city_name
          }
          email
          gender
          mobile
          profile_description
          qualification
          country
          register_number
          experience
          department
          dob
          address_line
          addressline1
          status
          state
          city
          res_users
          latitude
          longitude
          languages
          consulting
          password
          enable_hours
          patient_feedbacks_aggregate {
            aggregate {
              avg {
                priority_int
              }
            }
          }

        }
    }`,
    fetchPolicy:'network-only'
  })
  return this.postsQuery.valueChanges.pipe(
    tap((data) => {
        data;
    })
  );
}

refresh_editprofile(){
  this.postsQuery.refetch();
}

termsCondition(){
  return this.apollo
  .watchQuery({
    query: gql`{
      terms_doctors {
        condition
      }
  }`,
})
.valueChanges.pipe(
  tap((data) => {
      data;
  })
);
}


getdoctor_qualifications_languages(id:any,languages:any,qualifications:any){
  let qualification=qualifications.toString()
  let language=languages.toString()
  return this.apollo
    .watchQuery({
      query: gql`{
        doctor_registration_medical_qualification_rel(where: {medical_qualification_id: {_nin: [${qualification}]}, doctor_registration_id: {_eq: ${id}}}) {
          medical_qualification_id
        }
        doctor_registration_res_lang_rel(where: {res_lang_id: {_nin: [${language}]},doctor_registration_id: {_eq: ${id}}}) {
          res_lang_id
        }
    }`,
  })
  .valueChanges.pipe(
    tap((data) => {
        data;
    })
  );
}

delete_qualifications_languages(id:any,languages:any,qualifications:any){
  let qualification=qualifications.toString()
  let language=languages.toString()
  const delete_qualifications_languages = gql`
  mutation MyMutation {
    delete_doctor_registration_res_lang_rel(where: {res_lang_id: {_nin: [${language}]}, doctor_registration_id: {_eq: ${id}}}) {
      affected_rows
    }
    delete_doctor_registration_medical_qualification_rel(where: {medical_qualification_id: {_nin: [${qualification}]}, doctor_registration_id: {_eq: ${id}}}) {
      affected_rows
    }
  }`;
  return this.apollo.mutate({
    mutation: delete_qualifications_languages,
  }).pipe(
    tap((data) => {
      data;
    })
  );
}

get_country_list()
{
  return this.apollo
  .watchQuery({
    query: gql`
      {
        res_country(order_by: {name: asc}) {
          id,
          name
        }
      }
    `,
  })
  .valueChanges.pipe(
    tap((data) => {
        data;
    })
  );

}


mail_validation(data_value)
{
  return this.apollo
  .watchQuery({
    query: gql`
      {
        doctor_registration(where: {email: {_eq: "${data_value}"}}) {
          email
        }
      }
    `,
  })
  .valueChanges.pipe(
    tap((data) => {
        data;
    })
  );
}

mail_validation_resuser(data_value)
{
  const Checkresuseremail = gql`
  mutation fileupload {
    email_resuser(email:"${data_value}") {
      result {
        message
      }
    }
  }
    `;
    return this.apollo.mutate({
      mutation: Checkresuseremail,
    }).pipe(
      tap((data) => {
        data;
      })
    );
}

fileupload(id:any,images:any,token:any,filename:any)
{
  let imagedata={
    id:id,
    image:images,
    file_name:filename
  }
  const fileupload = gql`
      mutation fileupload($id: Int!,$image: String!,$file_name: String!) {
        doctorfileupload(id: $id, image: $image,file_name:$file_name) {
          result {
            message
          }
        }
      }`;
    return this.apollo.mutate({
      context: {
        headers: new HttpHeaders().set('Authorization', 'Bearer ' + token),
      },
      mutation: fileupload,
      variables: imagedata

    }).pipe(
      tap((data) => {
        data;
      })
    );
}



get_pre_medicine()
{
  return this.apollo
  .watchQuery({
    query: gql`
      {
        product_template {
          id
          name
        }
      }
    `,
  })
  .valueChanges.pipe(
    tap((data) => {
        data;
    })
  );
}

mobile_validation(data_value)
{

  // let mobile = data_value.value.mobile;
  return this.apollo
  .watchQuery({
    query: gql`
      {
        doctor_registration(where: {mobile: {_iregex: "${data_value}"}}) {
          mobile
        }
      }
    `,
  })
  .valueChanges.pipe(
    tap((data) => {
        data;
    })
  );
}

mobile_validation_resuser(data_value)
{

  const Checkresusermobile = gql`
  mutation fileupload {
    mobile_resuser(phone_number:"${data_value}") {
      result {
        message
      }
    }
  }
    `;
    return this.apollo.mutate({
      mutation: Checkresusermobile,
    }).pipe(
      tap((data) => {
        data;
      })
    );
}


get_city_list(){
      return this.apollo
      .watchQuery({
        query: gql` {
          res_city {
            name
            id

          } } `,
    })
    .valueChanges.pipe(
      tap((data) => {
          data;
      })
      );
}


get_qualifition_list()
{

  return this.apollo
      .watchQuery({
        query: gql` {
          medical_qualification {
            name
            id
          } } `,
    })
    .valueChanges.pipe(
      tap((data) => {
          data;
      })
      );

}




get_specialization_list(){
  return this.apollo
  .watchQuery({
    query: gql` {
      medical_speacialization {
        name
        id

      } } `,
})
.valueChanges.pipe(
  tap((data) => {
      data;
  })
  );
}



get_morning_slot()
{

    return this.apollo
        .watchQuery({
          query: gql` {
            many_times {
              id  times_data  write_date  write_uid create_date  create_uid
            } } `,
      })
      .valueChanges.pipe(
        tap((data) => {
            data;
        })
        );

}


consult_hour()
{

      return this.apollo
      .watchQuery({
        query: gql` {
          consultation_hour {
            id
            consultation_hour
          } } `,
    })
    .valueChanges.pipe(
      tap((data) => {
          data;
      })
      );


}


consult_hour_id(id,start,end,enable24hours)
{
  if(enable24hours){
    return this.apollo
    .watchQuery({
      query: gql` {
        many_times(where: {miniutes_differ: {_eq: ${id}}}, order_by: {float_time: asc}) {
          miniutes_differ
          times_data
          id
        } } `,
  })
  .valueChanges.pipe(
    tap((data) => {
        data;
    })
    );
  }
  else{
    return this.apollo
    .watchQuery({
      query: gql` {
        many_times(where: {float_time: {_gte: "${start}"}, _and: {float_time: {_lte: "${end}"},  miniutes_differ: {_eq: ${id}}}},order_by: {float_time: asc}){
          miniutes_differ
          times_data
          id
        } } `,
  })
  .valueChanges.pipe(
    tap((data) => {
        data;
    })
    );
  }




}


get_state_list()
{
    return this.apollo
        .watchQuery({
          query: gql` {
            res_country_state(where: {country_id: {_eq: 104}}) {
              country_id
              id
              name
            }}`,
      })
      .valueChanges.pipe(
        tap((data) => {
            data;
        })
        );


}

get_cities_list(id)
{
    return this.apollo
        .watchQuery({
          query: gql` {
            res_city(where: {state_id: {_eq: ${id}}}) {
              city_name
              id
              country_id
              state_id
            }}`,
      })
      .valueChanges.pipe(
        tap((data) => {
            data;
        })
        );
}




get_speacialization_list()
{
    return this.apollo
        .watchQuery({
          query: gql` {
            medical_speacialization(order_by: {name: asc}) {
              id
              name
            }}`,
      })
      .valueChanges.pipe(
        tap((data) => {
            data;
        })
        );
}


default_country()
{
  return this.apollo
  .watchQuery({
    query: gql`
      {
        res_country(where: {name: {_eq: "India"}}) {
          name
          id
        }
      }
    `,
  })
  .valueChanges.pipe(
    tap((data) => {
        data;
    })
  );

}



default_state()
{
  return this.apollo
  .watchQuery({
    query: gql`
      {
        res_country_state(where: {name: {_eq: "Tamil Nadu"}}) {
          name
          id
        }
      }
    `,
  })
  .valueChanges.pipe(
    tap((data) => {
        data;
    })
  );

}
getStates_bycountry(country_id:any){
  return this.apollo
    .watchQuery({
      query: gql`{
        res_country_state(where: {country_id: {_eq: ${country_id}}},order_by: {name: asc}) {
          id
          name
        }
    }`,
  })
  .valueChanges.pipe(
    tap((data) => {
        data;
    })
  );
}
getCities_bystate(state_id:any){
  return this.apollo
  .watchQuery({
    query: gql`{
      res_city(where: {state_id: {_eq: ${state_id}}},order_by: {city_name: asc}) {
        id
        city_name
      }
  }`,
  })
  .valueChanges.pipe(
    tap((data) => {
        data;
    })
  );
}
getSpecialization(){
  return this.apollo
    .watchQuery({
      query: gql`{
        medical_speacialization(order_by: {name: asc}) {
          id
          name
        }
    }`,
  })
  .valueChanges.pipe(
    tap((data) => {
        data;
    })
  );
}
getQualification(){
  return this.apollo
    .watchQuery({
      query: gql`{
        medical_qualification(order_by: {name: asc}) {
          id
          name
        }
    }`,
  })
  .valueChanges.pipe(
    tap((data) => {
        data;
    })
  );
}
update_resuser(resuser:any)
{
  const insert_resuser = gql`
      mutation updateresuser($email: String!,$name: String!,$user:String!,$gender:String!,$location:String!,$dob:String!,$mobileno:String!,$ids:Int!) {
        updateresuser(email: $email, name: $name, user: $user,gender:$gender,location:$location,dob:$dob,mobileno:$mobileno,ids:$ids) {
          result {
            message
          }
        }
      }`;

    return this.apollo.mutate({
      mutation: insert_resuser,
      variables: resuser

    }).pipe(
      tap((data) => {
        data;
      })
    );
}

update_doctor(token:String,doctor_details:any,doctor_id:any){
  const register_number=JSON.stringify(doctor_details.register_number);
  const mobile_number=JSON.stringify(doctor_details.mobile);
  // console.log(doctor_details)
  const update_doctor = gql`
  mutation MyMutation($id:Int!,$first_name: String!,$password:String!,$qualification: doctor_registration_medical_qualification_rel_arr_rel_insert_input,$gender:String!,$address_line:String!,$addressline1:String!,$city:Int!,$state:Int!,$country:Int!,$register_number:String!,$email:String!,$mobile:String!,$profile_description: String!,$dob: date!,$languages:doctor_registration_res_lang_rel_arr_rel_insert_input,$department:String!,$speacialization:Int!) {
    insert_doctor_registration(objects: {first_name: $first_name, id: $id, doctor_registration_medical_qualification_rels:$qualification,password:$password,gender:$gender,address_line:$address_line,addressline1:$addressline1,city:$city,state:$state,country:$country,register_number:$register_number,email:$email,mobile:$mobile,profile_description:$profile_description,dob:$dob,doctor_registration_res_lang_rels:$languages,department:$department,speacialization:$speacialization}, on_conflict: {constraint: doctor_registration_pkey, update_columns: [first_name,gender,email,mobile,address_line,addressline1,dob,department,speacialization,state,country,city,profile_description,password], where: {id: {_eq: $id}}}) {
      returning {
        id
      }
      affected_rows
    }
  }`;
  // console.log(token)
  return this.apollo.mutate({
    mutation: update_doctor,
    variables:doctor_details
  }).pipe(
    tap((data) => {
      data;
    })
  );
}
update_slot(slotdata,doctor_id){
  // console.log(doctor_id)
  console.log(slotdata)
  let slot = JSON.stringify(slotdata);
  // console.log(slot)
  slot = slot.replace(/"([^"]+)":/g, '$1:');
  slot=slot.replace( /"appointment_time_many_times_rel_pkey"/g ,'appointment_time_many_times_rel_pkey');
  // slot=slot.replace("many_times_id", 'many_times_id');
  slot=slot.replace(/"many_times_id"/g, 'many_times_id');
  slot=slot.replace(/"status"/g, 'status');


  console.log(slot)

  const update_slot = gql`
  mutation MyMutation {
    insert_appointment_time(objects:${slot} , on_conflict: {constraint: appointment_time_pkey, update_columns: [start_time,end_time,consultation_differ,write_date], where: {doctor_id: {_eq: ${doctor_id}}}}) {
      returning {
        id
      }
      affected_rows
    }
  }`;

  // const update_slot = gql`
  // mutation MyMutation {
  //   insert_appointment_time(objects: [{start_time:"",id:0,end_time:"",ava_timing:"Wednesday",consultation_differ:5,appointment_time_many_times_rels:{data:[{many_times_id:"295",status:0},{many_times_id:"298",status:0},{many_times_id:"300",status:0},{many_times_id:"302",status:0},{many_times_id:"306",status:0},{many_times_id:"309",status:0},{many_times_id:"311",status:0},{many_times_id:"312",status:0},{many_times_id:"314",status:0},{many_times_id:"315",status:0},{many_times_id:"317",status:0},{many_times_id:"318",status:0},{many_times_id:"334",status:0},{many_times_id:"335",status:0},{many_times_id:"336",status:0},{many_times_id:"337",status:0},{many_times_id:"338",status:0},{many_times_id:"339",status:0},{many_times_id:"340",status:0},{many_times_id:"341",status:0},{many_times_id:"343",status:0},{many_times_id:"345",status:0},{many_times_id:"347",status:0},{many_times_id:"348",status:0}],on_conflict:{constraint:appointment_time_many_times_rel_pkey,update_columns:[many_times_id,status]}}}], on_conflict: {constraint: appointment_time_pkey, update_columns: [appointment_time_id,ava_timing]}) {
  //     affected_rows
  //     returning {
  //       id
  //       ava_timing
  //     }
  //   }
  // }`;
  return this.apollo.mutate({
    mutation: update_slot,
  }).pipe(
    tap((data) => {
      data;
    })
  );
}
update_slotstatus(slotdata,doctor_id){
  // console.log(doctor_id)
  // console.log(slotdata)
  let slot = JSON.stringify(slotdata);
  slot = slot.replace(/"([^"]+)":/g, '$1:');
  slot=slot.replace( /"appointment_time_many_times_rel_pkey"/g ,'appointment_time_many_times_rel_pkey');
  slot=slot.replace(/"status"/g, 'status');
  console.log(slot)

  const update_slot = gql`
  mutation MyMutation {
    insert_appointment_time(objects:${slot} , on_conflict: {constraint: appointment_time_pkey, update_columns: [id], where: {doctor_id: {_eq: ${doctor_id}}}}) {
      returning {
        id
      }
      affected_rows
    }
  }`;

  // const update_slot = gql`
  // mutation MyMutation {
  //   insert_appointment_time(objects:[{id:1025,appointment_time_many_times_rels:{data:[{many_times_id:"152",status:"1"}],on_conflict:{constraint:appointment_time_many_times_rel_pkey,update_columns:status}}}] , on_conflict: {constraint: appointment_time_pkey, update_columns: [id], where: {doctor_id: {_eq: ${doctor_id}}}}) {
  //     returning {
  //       id
  //     }
  //     affected_rows
  //   }
  // }`;

  return this.apollo.mutate({
    mutation: update_slot,
  }).pipe(
    tap((data) => {
      data;
    })
  );
}

updatedoctorslot(doctor_id){
  const update_slot = gql`
  mutation MyMutation {
    updatedoctorslot(doctor_id: ${doctor_id}) {
      result {
        message
      }
    }
  }`;
  return this.apollo.mutate({
    mutation: update_slot,
  }).pipe(
    tap((data) => {
      data;
    })
  );
}

upload_profile_pic(data){
  const resgister_POST = gql`
  mutation profile_pic(
    $id: Int!,
    $image: String!,
    $relation: String!,
    $gender:String!
    ) {
      profile_upload(id: $id, image: $image ,relation: $relation,gender:$gender) {
        result {
          message
      }
    }
  }`;
    return this.apollo.mutate({
      mutation: resgister_POST,
      variables: data
    }).pipe(
      tap((data) => {
        data;
      })
    );
}
getdoctorslot_bydate(date:any,doctor_id:any){
  // console.log(date)
  return this.apollo
    .watchQuery({
      query: gql`{
        day_slot(where: {doctor_name: {_eq: ${doctor_id}},date_time: {_eq: "${date}"},status: {_eq: "active"}},order_by: {time_stamp: asc}) {
          id
          seven_days
          status
          time_available
          work_shift
          many_time {
            id
            times_data
          }
        }
    }`,
    fetchPolicy:'network-only'
  })
  .valueChanges.pipe(
    tap((data) => {
        data;
    })
  );
}

getcompleted_appointment_count(){
  return this.apollo
  .watchQuery({
    query: gql`{
      appointment_patient_aggregate(where: {status_id: {_eq: "complete"},payment_status:{_eq: "paid"}}) {
        aggregate {
          count(columns: status_id)
        }
      }
  },
  `,
  fetchPolicy: 'network-only',
},
)
.valueChanges.pipe(
  tap((data) => {
      data;
  })
);
}
getscheduled_appointment_count(){
  return this.apollo
  .watchQuery({
    query: gql`{
      appointment_patient_aggregate(where: { status_id: {_nin: "complete"},payment_status:{_eq: "paid"}}) {
        aggregate {
          count(columns: status_id)
        }
      }
  }`,
  fetchPolicy: 'network-only',
})
.valueChanges.pipe(
  tap((data) => {
      data;
  })
);
}

getworkinghours(doctor_id:any){
  return this.apollo
  .watchQuery({
    query: gql`{
      appointment_time(where: {doctor_id: {_eq: ${doctor_id}}}) {
        id
        start_time
        end_time
        ava_timing
        doctor_id
      }
  }`,
})
.valueChanges.pipe(
  tap((data) => {
      data;
  })
);
}

updateslot_availability(doctor_id:any,date:any,slots:any){
  // console.log(slots)
  const update_slots = gql`
  mutation MyMutation {
    update_day_slot(where: {id: {_in: [${slots}]}, date_time: {_eq: "${date}"}, doctor_name: {_eq: ${doctor_id}}}, _set: {time_available: "available"}) {
      returning {
        id
        status
        time_available
      }
    }
  }`;
  return this.apollo.mutate({
    mutation: update_slots,
  }).pipe(
    tap((data) => {
      data;
    })
  );
}

updateslot_appointment_status(appointment_id:any){
  const updateslot_appointment_status = gql`
  mutation MyMutation {
    update_appointment_patient(where: {id: {_eq: ${appointment_id}}}, _set: {status_id: "joined"}) {
      returning {
        id
      }
      affected_rows
    }
  }`;
  return this.apollo.mutate({
    mutation: updateslot_appointment_status,
  }).pipe(
    tap((data) => {
      data;
    })
  );
}


updateslot_notavailability(doctor_id:any,date:any,slots:any){
  // console.log(slots)
  const update_slots = gql`
  mutation MyMutation {
    update_day_slot(where: {id: {_in: [${slots}]}, date_time: {_eq: "${date}"}, doctor_name: {_eq: ${doctor_id}}}, _set: {time_available: "not_available"}) {
      returning {
        id
        status
        time_available
      }
    }
  }`;
  return this.apollo.mutate({
    mutation: update_slots,
  }).pipe(
    tap((data) => {
      data;
    })
  );
}
getdoctorfiles(doctor_id){
  this.doctorfiles=this.apollo
    .watchQuery({
      query: gql`{
        ir_attachment(where: {doctor_registration_ir_attachment_rels: {doctor_registration_id: {_eq: ${doctor_id}}}}) {
          name
          id
          mimetype
        }
    }`,
  })
  return this.doctorfiles.valueChanges.pipe(
    tap((data) => {
        data;
    })
  )
}
refresh_doctorfiles(){
  this.doctorfiles.refetch();
}

deletedoctorfile(ids){
  const deletedoctorfile = gql`
  mutation MyMutation {
    delete_ir_attachment(where: {id: {_in: [${ids}]}}) {
      affected_rows
      returning {
        id
      }
    }
  }`;
  return this.apollo.mutate({
    mutation: deletedoctorfile,
  }).pipe(
    tap((data) => {
      data;
    })
  );
}
getreviews_bydoctor(doctor_id,page,limit){
  return this.apollo
  .watchQuery({
    query: gql`{
      patient_feedback(where: {doctor_details: {_eq: ${doctor_id}}}, limit: ${limit}, offset: ${page},order_by: {id: desc}) {
        id
        feedback
        priority_int
        create_date
        doctor_details
        patient_registration {
          first_name
          id
        }
      }
  }`,
})
.valueChanges.pipe(
  tap((data) => {
      data;
  })
);
}
getsettledappointments(doctor_id,page){
  return this.apollo
  .watchQuery({
    query: gql`{
      appointment_patient(limit: 10, offset: ${page}, where: {doctor_details: {_eq: ${doctor_id}}, settlement: {_eq: true}}) {
        id
        paitents_details
        settlement
        patient_registration {
          first_name
          id
        }
        complaints
        reason
        date_time
        many_time {
          float_time
          times_data
        }
        day_slot {
          date_time
          many_time {
            times_data
          }
        }
      }
  }`,
})
.valueChanges.pipe(
  tap((data) => {
      data;
  })
);
}
getsettledprescription(doctor_id,page){
  return this.apollo
  .watchQuery({
    query: gql`{
      prescription_detail(limit: 10, offset: ${page}, where: {doctor_id: {_eq: ${doctor_id}}, settlement: {_eq: true}}){
      patient_registration {
          first_name
          id
        }
        appointment_patient_id {
          reason
          complaints
          many_time {
            times_data
          }
          day_slot {
            date_time
            many_time {
              times_data
            }
          }
        }
      }
  }`,
})
.valueChanges.pipe(
  tap((data) => {
      data;
  })
);
}
getDoctorSlots(doctor_id:any){
  return this.apollo
    .watchQuery({
      query: gql`{
        appointment_time(where: {doctor_id: {_eq: ${doctor_id}}}) {
          ava_timing
          shifts
          start_time
          end_time
          id
          appointment_time_many_times_rels(where: {status: {_eq: 0}}) {
            many_times_id
            appointment_time_id
            status
            many_time {
              times_data
              id
              miniutes_differ
            }
          }
          consultation_hour {
            consultation_hour
            id
          }
        }
    }`,
    fetchPolicy:'network-only'
  })
  .valueChanges.pipe(
    tap((data) => {
        data;
    })
  );
}

getslot_byday(doctor_id:any,selecteddata:any){
  return this.apollo
    .watchQuery({
      query: gql`{
        appointment_time(where: {doctor_id: {_eq: ${doctor_id}}, ava_timing: {_eq: ${selecteddata}}}) {
          ava_timing
          shifts
          start_time
          consultation_differ
          end_time
          id
          appointment_time_many_times_rels(where: {status: {_eq: 0}}) {
            many_times_id
            appointment_time_id
            status
            many_time {
              times_data
              id
              miniutes_differ
            }
          }
          consultation_hour {
            consultation_hour
            id
          }
        }
    }`,
    fetchPolicy:'network-only'
  })
  .valueChanges.pipe(
    tap((data) => {
        data;
    })
  );
}

get_doctor_id()
{

return this.apollo
.watchQuery({
query: gql`
  {
      doctor_registration {
        id
        first_name
      }
  }
`,
})
.valueChanges.pipe(
tap((data) => {
    data;
})
);

}
}
