import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { ApolloModule, Apollo, APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { InMemoryCache,ApolloLink ,split} from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';

const uri = 'https://graphql.mo.vc/v1/graphql'; // <-- add the URL of the GraphQL server here
// import { Storage } from '@capacitor/storage';
import {WebSocketLink} from '@apollo/client/link/ws';
import {getMainDefinition} from '@apollo/client/utilities';
const TOKEN_KEY = 'healthuno-marketplace-token';

export function createApollo(httpLink: HttpLink) {
  const basic = setContext((operation, context) => ({
    headers: {
      Accept: 'charset=utf-8'
    }
  }));

  const auth = setContext(async(_, { headers }) => {
    const token = localStorage.getItem('accessToken') || null;
    if(token) {
      return {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
    }
    else{
      return {};
    }
  });

  const http = ApolloLink.from([basic , auth, httpLink.create({ uri })]);

  const link = split(
    // split based on operation type
    ({query}) => {
      const data = getMainDefinition(query);
      return (
        data.kind === 'OperationDefinition' && data.operation === 'subscription'
      );
    },
    ws,
    http,
  );
  const cache = new InMemoryCache();

  return {
    link,
    cache
  }
}

const ws = new WebSocketLink({
  uri: `wss://graphql.mo.vc/v1/graphql`,
  options: {
    reconnect: true,
    lazy:true,
    connectionParams: async () => {
      const token = await localStorage.getItem('accessToken')
      return {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
    }
  },
});

@NgModule({
  exports: [
    HttpClientModule,
    ApolloModule,
  ],
  providers: [{
    provide: APOLLO_OPTIONS,
    useFactory: createApollo,
    deps: [HttpLink]
  }]
})
export class GraphQLModule {}

