import { Component,OnDestroy } from '@angular/core';
import{ Router } from '@angular/router';
import { AuthenticationServiceService } from './services/authentication-service.service';
import { CommonServiceService } from './services/common-service.service';
import { Subject,Subscription } from 'rxjs';
import { LocationAccuracy } from '@awesome-cordova-plugins/location-accuracy/ngx';

import { Network } from '@awesome-cordova-plugins/network/ngx';
import { AlertController } from '@ionic/angular';
import { NotificationService } from './services/notification.service';
import { Platform } from '@ionic/angular';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { MenuController } from '@ionic/angular';
import { Preferences } from '@capacitor/preferences';
import OneSignal from 'onesignal-cordova-plugin';



import 'hammerjs';
// import { OneSignal } from '@ionic-native/onesignal/ngx';




@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  private countSubscription: Subscription;
  private completedcountSubscription: Subscription;
  connectSubscription:Subscription;
  disconnectSubscription:Subscription;


  navigate : any;
  user: any;
  doctorDetails:any=[];
  completed_count:any
  token:any;
  scheduled_count:any
  public user_id:any;
  profile_pic:any
  platform_type:any
  res_users:any

  constructor(
  private router: Router,
  public _authenticationService: AuthenticationServiceService,
  private commonservice:CommonServiceService,
  private locationAccuracy: LocationAccuracy,
  // private onesignal: OneSignal
  private network: Network,
  private alertCtrl:AlertController,
  private notify:NotificationService,
  private platform:Platform,
  private androidPermissions: AndroidPermissions,
  public menuCtrl: MenuController,
  ) {
    platform.ready().then(() => {
      this.setuppush();
    });
    this.sideMenu()
    this.initializeApp();
    // this.menuCtrl.enable(true)
    this.getprofile();

    this.commonservice.profileimage.subscribe((data)=>{
      console.log(data)
      this.profile_pic=data
    })

    if(this.platform.is('android')){
      this.platform_type="android"
    }

    Preferences.get({ key: 'intro' }).then(user => {
      console.log('val',user)
      if(user.value == null){
        Preferences.set({ key: 'intro', value: 'true' });
        
        this.router.navigateByUrl('/initial-slider');
        }
    });

    // this.locationAccuracy.canRequest().then((canRequest: boolean) => {
    //   if(canRequest) {
    //     // the accuracy option will be ignored by iOS
    //     this.locationAccuracy.request(this.locationAccuracy.REQUEST_PRIORITY_HIGH_ACCURACY).then(
    //       () => console.log('Request successful'),
    //       error => console.log('Error requesting location permissions', error)
    //     );
    //   }
    // });
    this.checkGPSPermission()

  }
  async setuppush()
  {
  // this.onesignal.startInit('75120494-dbc9-451c-bf27-557c6086d22c','295680852859');
  OneSignal.setAppId("75120494-dbc9-451c-bf27-557c6086d22c");
  var that=this
  OneSignal.setNotificationOpenedHandler(function(jsonData) {
    console.log('notificationOpenedCallback: ' + JSON.stringify(jsonData));
    let add_data=jsonData.notification.additionalData
    console.log('json',add_data['type'])
    if (add_data['type'] == 'individual'){
        that.router.navigate(['/patient-chat/' + add_data['appoint_id']]);
    }
    else if (add_data['type'] == 'support'){
      that.router.navigate(['/support']);
    }
    else{
      that.router.navigate(['/appoint-details/' +add_data['appoint_id']]);
    }
  });
  // this.onesignal.inFocusDisplaying(this.onesignal.OSInFocusDisplayOption.InAppAlert);
  // this.onesignal.handleNotificationReceived().subscribe(data=> {
  // console.log("received");
  // console.log(data);


  // });

    // this.onesignal.handleNotificationOpened().subscribe(data=> {
    //   console.log(data)
    //   if(data.notification.payload.additionalData){
    //     // if(data.notification.payload.additionalData.type=='support'){
    //     //   alert("support")
    //     //   this.router.navigate(['/support']);

    //     // }else if(data.notification.payload.additionalData.type=='individual'){
    //     //   alert("individual")

    //       this.router.navigate(['/patient-chat/' + data.notification.payload.additionalData.appoint_id]);
    //     // }else{
    //     //   alert("appointment")
    //     //   this.router.navigate(['/appoint-details/' + data.notification.payload.additionalData.appoint_id]);
    //     // }
    //   }
    // });

    // this.onesignal.endInit();


}
  initializeApp(){
    this.platform.ready().then(() => {
      this.backButtonEvent();
      this.network_diconnection();
    });
  }
  backButtonEvent(){
    this.platform.backButton.subscribeWithPriority(1, async ()=>{
      // console.log(this.router.url)
      if (this.router.url === '/home/home-list') {
        this.presentAlertConfirm();
      }
      else {
          window.history.back();
      }
      })
  }
  async presentAlertConfirm() {
    const alert = await this.alertCtrl.create({
      message: 'Are you sure you want to exit the app?',
      buttons: [{
        text: 'Cancel',
        role: 'cancel',
        cssClass: 'secondary',
        handler: (blah) => {}
      }, {
        text: 'Close App',
        handler: () => {
          navigator['app'].exitApp();
        }
      }]
    });
    await alert.present();
  }

  async openalert(){
    const alert=this.alertCtrl.create({
      header:'Check you internet connection',
      message:'Please Turn on your mobile data',
      buttons: [
        {
          text: 'ok',
          handler: () => {

          }
        }
      ]
    }).then(res => {
      res.present();
    });
  }

  ngOnInit() {
  console.log("ngonit")

  }

  getprofile(){
    this._authenticationService.userdata.subscribe(data=>{
      console.log("subscrbe")
      this.user=data;
      if(this.user){
        // console.log("check")
        // console.log(this.token)
        // console.log(this.user)
        this.getcompleted_appointment_count();
        this.getscheduled_appointment_count();
        this.getcurrentdoctor();
      }
    });
  }
  getcurrentdoctor(){
    this.commonservice.getUserdata(this.token).subscribe((data: any)=>{
        if(data.data.doctor_registration.length==0){
          this.countSubscription.unsubscribe();
          this.completedcountSubscription.unsubscribe();
          this._authenticationService.logout();
        }else{
          this.doctorDetails=data.data.doctor_registration[0];
          this.res_users=this.doctorDetails.res_users


        }
        // console.log(this.doctorDetails)
    })
  }




  getcompleted_appointment_count(){
    this.countSubscription=this.commonservice.getcompleted_appointment_count().subscribe((data:any)=>{
      if(data)
      {
        this.completed_count=data.data.appointment_patient_aggregate.aggregate.count
      }
    })
  }
  getscheduled_appointment_count(){
    this.completedcountSubscription=this.commonservice.getscheduled_appointment_count().subscribe((data:any)=>{
      if(data)
      { this.scheduled_count=data.data.appointment_patient_aggregate.aggregate.count }
    })
  }

  sideMenu()
  {
    this.navigate =
    [
      {
        title : "Home",
        url   : "home/home-list",
        src  : 'assets/home-1.svg'
      },
      {
        title : "Profile Overview",
        url   : "home/profile",
        src  : 'assets/profile.svg'
      },
      {
        title : "Health Tips",
        url   : "health-tips",
        src  : 'assets/health-tips.svg'
      },
      {
        title : "Blogs",
        url   : "home/blog",
        src  : 'assets/Blog.svg'
      },
      {
        title : "Help and Support",
        url   : "helpsupport",
        src  : 'assets/support-1.svg'
      },
      {
        title : "Terms and Conditions",
        url   : "terms",
        src: 'assets/terms-1.svg'
      },
      {
        title : "FAQs",
        url   : "faq",
        src  : 'assets/FAQS.svg'
      },
      {
        title : "About HealthUno",
        url   : "home/aboutus",
        src  : 'assets/about_1.svg'
      },
    ]
  }
  network_diconnection(){
    this.disconnectSubscription = this.network.onDisconnect().subscribe(() => {
      // this.notify.presentToast('No internet connection','dark','bottom');
      // console.log('network was disconnected :-(');
      let message="<img src='assets/images/nointernet.jpg'/>"+"<div><p>Please check your internet connection</p></div>";
      this.createAlert('Network Disconnected', message ).then(alert => {
        alert.present();
      });

      this.network_connection();
    });
  }
  async createAlert(header, message): Promise<HTMLIonAlertElement> {
    const alert = await this.alertCtrl.create({
    header,
    cssClass: 'nointernet',
    backdropDismiss: false,
    message
    });
    return alert;
  }

  network_connection(){
    this.unsubscribe_disconect();
    this.connectSubscription = this.network.onConnect().subscribe(() => {
      // this.notify.presentToast('Network connected!','dark','bottom');
      this.network_diconnection();
      // console.log('network connected!');
      this.alertCtrl.dismiss();
      this.unsubscribe_connect();
      setTimeout(() => {
        if (this.network.type === 'wifi') {
          this.network_diconnection();
          // this.notify.presentToast('Network connected!','dark','bottom');
          console.log('we got a wifi connection, woohoo!');
          this.unsubscribe_connect();
        }
      }, 3000);
    });

  }
  unsubscribe_disconect(){
    this.disconnectSubscription.unsubscribe();

  }
  unsubscribe_connect(){
    this.connectSubscription.unsubscribe();
  }




  logout(data)
  {
    let onesignalid=localStorage.getItem('onesignalid')
    this._authenticationService.delete_onesignalkey(onesignalid,this.platform_type,this.res_users).subscribe((data)=>{
      if(this.countSubscription){
        this.countSubscription.unsubscribe();
      }
      if(this.completedcountSubscription){
        this.completedcountSubscription.unsubscribe();
      }
      this._authenticationService.logout();
    },(err)=>{
      if(this.countSubscription){
        this.countSubscription.unsubscribe();
      }
      if(this.completedcountSubscription){
        this.completedcountSubscription.unsubscribe();
      }
      this._authenticationService.logout();
    })


  }
  ngOnDestroy(){
    console.log("Destroy");

    this.countSubscription.unsubscribe();
    this.completedcountSubscription.unsubscribe();
  }


  async checkGPSPermission() {
    return new Promise((resolve, reject) => {
       this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.ACCESS_COARSE_LOCATION).then(
    result => {
      console.log(result.hasPermission);
      if (result.hasPermission) {
          console.log('hasPermission-YES');
         //If having permission show 'Turn On GPS' dialogue
         this.askToTurnOnGPS().then((response) => {

       });
     } else {
       console.log('hasPermission-NO');
       //If not having permission ask for permission
       this.requestGPSPermission().then((response) => {
          console.log(response, 'requestGPSPermission-checkGPSPermission');

        });
       }
     },
     err => {
      //  alert(err);
       reject(false);
     });
  });
 }

 async askToTurnOnGPS() {
  return new Promise((resolve, reject) => {
  this.locationAccuracy.request(this.locationAccuracy.REQUEST_PRIORITY_HIGH_ACCURACY).then((resp) => {
    console.log(resp, 'location accuracy');
    error => {
    //  alert('Error requesting location permissions');
     reject(false);
    }
  });
});
}

async requestGPSPermission() {
  return new Promise((resolve, reject) => {
  this.locationAccuracy.canRequest().then((canRequest: boolean) => {
  if (canRequest) {
     console.log("4");
  } else {
      this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.ACCESS_COARSE_LOCATION).then(() => {
      this.askToTurnOnGPS().then((response) => {

        });
  },
   error => {
    // alert('requestPermission Error requesting location permissions ' + error);
    reject(false);
   });
  }
 });
});
}



}
