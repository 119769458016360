import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthenticationServiceService } from './authentication-service.service';


@Injectable({
  providedIn: 'root'
})
export class AuthGuardServiceService {

  constructor(private router: Router,public authenticationService: AuthenticationServiceService) { }

  canActivate(): boolean {
    if (this.authenticationService.isAuthenticated()) {
        return true;
    }else{  this.authenticationService.logout();  return false; }
  }

  canActivateChild(): boolean {
    if (!this.authenticationService.isAuthenticated()) {
      this.authenticationService.logout();
      return false;
    } else{return true;}
  }
}
